<template>
  <div class="product-parameters-bundle">
    <ProductCardModal
      :product="product"
      :priceRange="priceRange"
      :quantity="quantity"
      :products="upSell"
    ></ProductCardModal>

    <div class="row">
      <div class="col">
        <ProductPricing
          :product="product"
          :priceRange="priceRange"
          :productBundle="productBundles"
        ></ProductPricing>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div
          class="bundel-product"
          v-for="bundleProd of productBundles"
          :key="`bund` + bundleProd.option_id"
        >
          <template v-if="bundleProd.options.length === 1">
            <div
              v-for="(bundleOption, idx) of bundleProd.options"
              :key="bundleOption.id"
            >
              <div
                class="product-info-wrap"
                v-if="bundleOption.product != null"
              >
                <div class="bundel-img">
                  <img :src="bundleOption.product.image.small" />
                </div>
                <div class="product-details">
                  <div class="product-name">
                    {{ bundleOption.product.name }}
                  </div>
                  <div
                    class="options-container"
                    v-if="bundleOption.product.stock_status == 'IN_STOCK'"
                  >
                    <div
                      class="bundel-bottom"
                      v-for="parameter of options[bundleProd.uid]"
                      :key="parameter.id"
                    >
                      <div class="size-selection">
                        <div
                          class="selection-category d-flex justify-content-between"
                        >
                          <span class="select-size">{{ parameter.label }}</span>
                          <div class="size-selection-radio">
                            <ul class="size-selection--available-sizes">
                              <li
                                v-for="opt of parameter.values"
                                :key="opt.value_index"
                              >
                                <label class="custom-radio-input">
                                  <input
                                    type="radio"
                                    :id="`${bundleProd.option_id}-${parameter.attribute_code}-${opt.value_index}`"
                                    :name="`${bundleProd.option_id}-${parameter.attribute_code}-select`"
                                    :value="opt.value_index"
                                    v-on:click="
                                      processOption(
                                        bundleProd.uid,
                                        parameter.index,
                                        opt.value_index
                                      )
                                    "
                                    :checked="
                                      handleChecked(
                                        bundleProd.uid,
                                        opt.value_index
                                      )
                                    "
                                  />
                                  <span
                                    class="custom-radio-input--btn font-weight-normal"
                                    >{{ opt.label }}</span
                                  >
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pro-input">
                      <label>{{ $t("Quantity") }}</label>
                      <input
                        type="text"
                        @change="
                          changeOptionQuantity($event, bundleProd.uid, idx)
                        "
                        :value="bundleOption.quantity"
                        :disabled="bundleOption.can_change_quantity == false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                {{ $t("Product option not available") }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="row" v-if="showButton">
      <div class="col">
        <div class="d-flex quantity-and-btn">
          <div class="quantity-input d-flex">
            <b-link
              href="#"
              class="step-down-btn"
              @click="quantityDown"
            ></b-link>
            <input
              type="number"
              min="1"
              :value="quantity"
              name="quantity"
              ref="inputQuantity"
              disabled
            />
            <b-link href="#" class="step-up-btn" @click="quantityUp"></b-link>
          </div>
          <button
            v-on:click="addToCart"
            v-bind:disabled="btnDisable"
            :style="buttonClass"
            class="btn btn-info product-parameter-submit-btn"
          >
            <i class="lnr lnr-cart"></i>{{ $t("add_to_cart") }}
          </button>
        </div>
        <span
          class="d-block text-info delivery-info mb-20"
          v-if="productDeliveryTime != null"
          :style="'color: ' + productDeliveryTime.color + '!important'"
        >
          {{ productDeliveryTime.long }}</span
        >
        <div
          class="d-flex flex-column flex-md-row justify-content-between more-info"
          v-if="availability.length > 0"
        >
          <div class="available-stores mb-20 mb-md-0">
            <span class="d-block mb-3">Beschikbaarheid winkels:</span>
            <div
              v-for="(astore, index) in availability"
              :key="`astores-${index}`"
            >
              <span class="d-block">{{ astore.store.name }}</span>
            </div>
          </div>
          <div class="stock">
            <span class="d-block mb-3">&nbsp;</span>
            <div
              v-for="(astore, index) in availability"
              :key="`sstores-${index}`"
            >
              <span v-if="astore.has_stock == true" class="d-block text-info">{{
                $t("in_stock")
              }}</span>
              <span v-if="astore.has_stock != true" class="d-block">{{
                $t("not_in_stock")
              }}</span>
            </div>
          </div>
        </div>
        <div class="partners">
          <div v-if="typeof paymentPartners == 'object'">
            <ul class="payment-partners">
              <li v-for="(image, index) in paymentPartners.slides" :key="index">
                <img :src="image.media.url" :alt="image.title" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <SocialShare :product="product" />
    </div>
    <div class="row" v-else>
      <div class="col missing-bundle-option pb-20">
        {{
          $t(
            "This product is out of stock. Because of this, this bundle can't be ordered."
          )
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { mapGetters } from "vuex";
import ProductCardModal from "@/base/core/components/product/ProductCardModal";
import ProductPricing from "@/base/core/components/product/ProductPricing";
import SocialShare from "@/base/core/components/product/ProductSocialShare";

export default {
  name: "ProductParametersBundle",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ProductCardModal,
    ProductPricing,
    SocialShare,
  },
  props: {
    parameters: { type: Object },
    isInWishList: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      productConfig: "product/getCurrentConfig",
      productBundles: "product/getCurrentProdBundle",
      selectedBundles: "product/getCurrentProdSelectedBundles",
      selectedBundleChildSku: "product/getCurrentProdSelectedChildSkus",
      options: "product/getCurrentProdBundleOptions",
      crossSell: "product/getCrossSellProducts",
      upSell: "product/getUpSellProducts",
    }),
    btnDisable() {
      return !Object.keys(this.selectedBundles).every(
        (key) => this.selectedBundleChildSku[key]
      );
    },
    buttonClass() {
      if (this.btnDisable == true) {
        return "cursor: not-allowed;";
      } else {
        return "";
      }
    },
    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
    finalPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.final_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.final_price.value !=
          this.product.price_range.maximum.final_price.value
        ) {
          return (
            this.product.price_range.minimum_price.final_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.final_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.final_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.final_price.value !=
        this.product.price_range.maximum.final_price.value
      ) {
        return (
          this.product.price_range.minimum_price.final_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.final_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.final_price.value.toFixed(
        2
      );
    },
    priceRange() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range;
          }
        }
        return this.product.price_range;
      }
      return this.product.price_range;
    },
    availability() {
      if (this.product.__typename == "SimpleProduct") {
        return this.product.store_availability;
      }
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.store_availability;
          }
        }
      }

      return [];
    },
    showButton() {
      let productFound = true;
      this.productBundles.forEach((bundle) => {
        if (bundle.options) {
          if (bundle.options.length > 1) {
            bundle.options.forEach((option) => {
              if (option.products) {
                productFound = productFound && true;
              }
            });
          } else if (bundle.options.length == 1) {
            if (bundle.options[0].product) {
              productFound = productFound && true;
            } else {
              productFound = productFound && false;
            }
          }
        } else {
          productFound = false;
        }
      });
      return productFound;
    },
    productDeliveryTime() {
      return this.getProductDeliveryTime();
    },
  },

  data: () => ({
    quantity: 1,
  }),
  methods: {
    handleChecked(bundle_id, selected_val) {
      const checked =
        !!this.selectedBundleChildSku &&
        !!this.selectedBundleChildSku[bundle_id] &&
        this.selectedBundleChildSku[bundle_id] == selected_val;
      return checked;
    },
    clickOk() {
      this.$router.push("/checkout");
    },
    clickCancel() {
      this.$router.push("/");
    },
    quantityUp() {
      this.quantity++;
    },
    quantityDown() {
      if (this.quantity > 1) this.quantity--;
    },
    processOption(bundle_id, index, value) {
      this.$store.commit("product/setCurrentProdBundleOptionValue", {
        bundle_id: bundle_id,
        index: index,
        value: value,
      });
    },
    changeOptionQuantity(event, bundle_id, index) {
      const quantity = +event.target.value;
      this.$store.commit("product/setCurrentProdBundleOptionQty", {
        bundle_id: bundle_id,
        index: index,
        value: quantity,
      });
    },
    async addToCart() {
      switch (this.product.__typename) {
        case "BundleProduct": {
          let item =
            "{ data: { sku: " +
            '"' +
            this.product.sku +
            '" quantity:' +
            this.quantity +
            "} " +
            "bundle_options: [";
          const bundles = this.productBundles;
          const parentSkus = this.selectedBundles;
          const childSkus = this.selectedBundleChildSku;
          bundles.forEach((element) => {
            let quantity = 1;
            const foundOption =
              element.options &&
              element.options.find(
                (opt) => opt.product.sku == parentSkus[element.uid]
              );

            if (foundOption) quantity = foundOption.quantity;

            let option =
              " { id: " +
              element.option_id +
              " quantity: " +
              quantity +
              " value: [";
            option = option + "{ id: " + element.options[0].id;
            option = option + ' parent_sku: "' + parentSkus[element.uid] + '"';
            option = option + ' variant_sku: "' + childSkus[element.uid] + '"}';
            option = option + "]},";
            item = item + option;
          });
          item = item + "] }";

          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          if (retval != false) this.$bvModal.show("ProductCardModal");
          break;
        }

        default:
          break;
      }
    },
    getProductDeliveryTime() {
      var DeliveryTime = this.product.DeliveryTime;
      Object.entries(this.selectedBundleChildSku).forEach((bundle) => {
        const [key, value] = bundle;
        this.productBundles.forEach((productBundle) => {
          if (productBundle.uid !== key) {
            return;
          }
          productBundle.options[0].product.variants.forEach((variant) => {
            if (variant.product.sku !== value) {
              return;
            }
            if (DeliveryTime.priority < variant.product.DeliveryTime.priority) {
              DeliveryTime = variant.product.DeliveryTime;
            }
          });
        });
      });
      return DeliveryTime;
    },
  },
  watch: {
    selectedBundleChildSku() {
      this.getProductDeliveryTime();
    },
  },
};
</script>
