<template>
  <div class="partners">
    <div v-if="typeof paymentPartners == 'object'">
      <ul class="payment-partners">
        <li v-for="(image, index) in paymentPartners.slides" :key="index">
          <img :src="image.media.url" :alt="image.title" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
  },
};
</script>

