<template>
  <div class="social-sharing">
    <p>
      <span>{{ $t("share_product_on") }}</span>
      <ShareNetwork
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :url="getFullPath"
        :title="product.name"
        :description="product.meta_description"
        :hashtags="getHashTags"
      >
        <span class="lnr lnr-envelope" v-if="network.iconType == 'lnr'"></span>
        <font-awesome-icon :icon="[network.iconType, network.icon]" v-else />
      </ShareNetwork>
    </p>
  </div>
</template>
<script>
import config from "@config";
export default {
  name: "ProductSocialShare",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    networks: [
      {
        network: "email",
        name: "Email",
        iconType: "lnr",
        icon: "lnr-envelope",
      },
      {
        network: "facebook",
        name: "Facebook",
        iconType: "fab",
        icon: "facebook",
      },
      {
        network: "linkedin",
        name: "LinkedIn",
        iconType: "fab",
        icon: "linkedin",
      },
      {
        network: "messenger",
        name: "Messenger",
        iconType: "fab",
        icon: "facebook-messenger",
      },
      {
        network: "pinterest",
        name: "Pinterest",
        iconType: "fab",
        icon: "pinterest",
      },
      { network: "sms", name: "SMS", iconType: "fas", icon: "comment-dots" },
      {
        network: "telegram",
        name: "Telegram",
        iconType: "fab",
        icon: "telegram-plane",
      },
      { network: "twitter", name: "Twitter", iconType: "fab", icon: "twitter" },
      {
        network: "whatsapp",
        name: "Whatsapp",
        iconType: "fab",
        icon: "whatsapp",
      },
    ],
  }),
  computed: {
    getFullPath() {
      return config.app_url + "/" + this.$route.fullPath;
    },
    getHashTags() {
      return this.product.meta_keyword
        ? this.product.meta_keyword.replace(/\s*,\s*/g, ",")
        : "";
    },
  },
};
</script>