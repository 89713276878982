<template>
  <div v-if="typeof product != 'undefined'" class="bundleTips">
    <span class="title d-block text-center">Bundel tips</span>
    <div class="img-holder position-relative">
      <div
        class="img"
        :style="{ backgroundImage: `url(${product.thumbnail.large})` }"
      ></div>
      <div class="discount-label text-center">
        <span class="d-block"
          >{{ $t("from") }} &euro;{{
            product.price_range.maximum_price.regular_price.value
              .toFixed(2)
              .replace(".", ",")
          }}
          {{ $t("to") }} &euro;{{
            product.price_range.minimum_price.final_price.value
              .toFixed(2)
              .replace(".", ",")
          }}</span
        >
        <mark class="d-block"
          >{{ $t("discount") }}
          {{
            product.price_range.minimum_price.discount.percent_off.toFixed(0)
          }}%</mark
        >
      </div>
    </div>
    <ul class="bundel-items d-none d-md-flex">
      <li
        v-for="bundelItem in product.items"
        :key="product.items.indexOf(bundelItem)"
      >
        {{ bundelItem.title }}
      </li>
    </ul>
    <div class="text-center d-none d-md-block">
      <b-link :to="`/` + product.url_key" class="bundles-btn">
        Bekijk nu!
      </b-link>
    </div>
  </div>
  <div v-else>
    <cmsBlockSimple
      identifier="bundle_tips_alternative"
      class="bundle_tips_alternative"
    />
  </div>
</template>

<script>
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

export default {
  name: "BundelTips",
  components: { cmsBlockSimple },
  data: () => ({}),
  computed: {
    product() {
      const prod = this.$store.getters["home/getRandomBundles"](1)[0];
      return prod;
    },
  },
};
</script>

