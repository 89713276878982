<template>
  <div class="productSizeSelection">
    <ProductMeasureCompare
      :parameter="parameter"
      :product-config="productConfig"
      @selectSize="(selectedVal) => (priceSize = selectedVal)"
    ></ProductMeasureCompare>

    <div class="size-selection">
      <div class="selection-category d-flex justify-content-between">
        <span class="select-size">{{ parameter.label }}</span>
        <b-link
          v-if="
            parameter.attribute_code == 'fkv_maten' &&
            productConfig.size_chart &&
            productConfig.size_chart.instructions_image
          "
          v-b-modal.modal-scrollable
          class="size-info ml-10"
          href="#"
          >{{ $t("view_sizechart") }}</b-link
        >
      </div>
      <div
        v-if="parameter.attribute_code == 'fkv_maten'"
        class="select-wrapper"
      >
        <el-select
          v-model="priceSize"
          :placeholder="$t('select_measure')"
          v-on:change="processOption(parameter.index, priceSize)"
          popper-class="productSizeSelect"
        >
          <clientOnly
            ><template slot="prefix">{{ priceSize }}</template></clientOnly
          >
          <el-option
            v-for="opt of parameter.values"
            :key="opt.value_index"
            :label="opt.label"
            :value="opt.value_index"
          >
            <div>
              <span>{{ opt.label }}</span>
            </div>
            <div
              :style="'color: ' + getDeliverytimecolor(opt.value_index)"
              class="w-100"
            >
              <span>{{ getDeliverytime(opt.value_index) }}</span>
            </div>
            <span>&euro; {{ opt.price }}</span>
          </el-option>
        </el-select>
      </div>
    </div>

    <div
      v-if="parameter.attribute_code != 'fkv_maten'"
      class="size-selection-radio"
    >
      <ul class="size-selection--available-sizes">
        <li v-for="opt of parameter.values" :key="opt.value_index">
          <label class="custom-radio-input">
            <input
              :id="`${parameter.attribute_code}-${opt.value_index}`"
              :name="`${parameter.attribute_code}-select`"
              v-model="priceSize"
              :value="opt.value_index"
              type="radio"
              v-on:click="processOption(parameter.index, opt.value_index)"
            />
            <span class="custom-radio-input--btn font-weight-normal">{{
              opt.label
            }}</span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Option, Select } from "element-ui";
import ProductMeasureCompare from "@/base/core/components/product/ProductMeasureCompare";
import ClientOnly from "vue-client-only";

export default {
  components: {
    ProductMeasureCompare,
    [Select.name]: Select,
    [Option.name]: Option,
    ClientOnly,
  },
  name: "ProductSizeSelection",
  props: {
    parameter: { type: Object },
    productConfig: Object,
  },
  computed: {
    ...mapGetters({
      options: "product/getCurrentProductOptions",
    }),
  },
  data: () => ({
    priceSize: "",
  }),
  methods: {
    getDeliverytime(sku) {
      const found = this.productConfig.variants.find((v) => {
        if (v.product.sku == sku) return true;
      });
      if (found) {
        return found.product.DeliveryTime.long.replace(/<\/?[^>]+>/gi, " ");
      }
      return "";
    },
    getDeliverytimecolor(sku) {
      const found = this.productConfig.variants.find((v) => {
        if (v.product.sku == sku) return true;
      });
      if (found) {
        return found.product.DeliveryTime.color;
      }
      return "";
    },
    processOption(index, value) {
      this.$store.commit("product/setOptionValue", {
        index: index,
        value: value,
      });
    },
  },
};
</script>