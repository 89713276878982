<template>
  <div class="product-gallery">
    <div
      class="selected-image-holder d-none d-md-block"
      @click.prevent="showMultiple"
    >
      <b-link href="#" @click.stop="toggleIsInWishList">
        <i class="lnr lnr-heart"></i>
        <div class="heart-icon" v-if="isInWishList">
          <img src="@/base/assets/heart.png" alt="heart" />
        </div>
      </b-link>
      <div
        class="selected-image"
        :style="{ backgroundImage: `url(${selectedImage})` }"
      ></div>
    </div>
    <ClientOnly>
      <Lightbox
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
      ></Lightbox>
      <Carousel
        class="carousel"
        :autoplay="false"
        :loop="true"
        :navigationEnabled="true"
        :perPage="noOfSlidePerPage"
        :scrollPerPage="false"
        :paginationEnabled="false"
        navigationPrevLabel="<i class='lnr lnr-chevron-left'></i>"
        navigationNextLabel="<i class='lnr lnr-chevron-right'></i>"
      >
        <b-link
          href="#"
          @click="toggleIsInWishList"
          class="mobile-add-to-wishlist"
        >
          <i class="lnr lnr-heart"></i>
          <div class="heart-icon" v-if="isInWishList">
            <img src="@/base/assets/heart.png" alt="heart" />
          </div>
        </b-link>
        <Slide v-for="(image, i) of images" :key="`${i}`" :id="`slide ${i}`">
          <b-link href="#" class="slide-img-holder" @click="clickSlideGallery">
            <div
              class="slide-img"
              :style="{
                backgroundImage: `url(${
                  is_mobile ? image.large : image.small
                })`,
              }"
            ></div>
          </b-link>
        </Slide>
      </Carousel>
    </ClientOnly>
  </div>
</template>

<script>
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";
import { isMobile } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";

export default {
  name: "ProductGallery",
  props: ["images", "isInWishList"],
  components: {
    ClientOnly,
    Carousel: () =>
      !isServer ? import("vue-carousel").then((m) => m.Carousel) : null,
    Slide: () =>
      !isServer ? import("vue-carousel").then((m) => m.Slide) : null,
    Lightbox: () => (!isServer ? import("vue-easy-lightbox") : null),
  },
  data() {
    return {
      selectedImage: this.images[0].large,
      noOfSlidePerPage: 1,
      visible: false,
      index: 0,
      slideNumber: 0,
    };
  },
  watch: {
    images: function (oldVal, newVal) {
      this.selectedImage = newVal[0].large;
      this.selectedImage = oldVal[0].large;
    },
  },
  computed: {
    imgs: {
      get() {
        var data = [];
        this.images.forEach((element) => {
          data.push(element.x_large);
        });
        return data;
      },
      set() {
        //Nothing to do
      },
    },
    is_mobile() {
      return isMobile && !isTablet;
    },
  },
  mounted() {
    window.addEventListener("resize", this.setNoOfSlidePerPage);
    window.addEventListener("load", this.setNoOfSlidePerPage);
    this.setNoOfSlidePerPage();
  },
  destroyed() {
    window.removeEventListener("resize", this.setNoOfSlidePerPage);
    window.removeEventListener("load", this.setNoOfSlidePerPage);
  },
  methods: {
    clickSlideGallery(e) {
      this.changeSelectedImage(e);
      if (window.innerWidth < 768) {
        this.showMultiple();
      }
    },
    changeSelectedImage(e) {
      const slideNumber = e.target.parentElement.id.replace(/^\D+/g, "");
      this.slideNumber = +slideNumber;
      this.selectedImage = this.images[slideNumber].large;
    },
    showMultiple() {
      this.imgs = this.images;
      this.index = this.slideNumber;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    setNoOfSlidePerPage() {
      this.noOfSlidePerPage = window.innerWidth < 768 ? 1 : 3;
    },
    toggleIsInWishList() {
      this.$emit("changeIsInWishList");
    },
  },
};
</script>
