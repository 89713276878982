<template>
  <div v-if="product.__typename == 'ConfigurableProduct' && productConfig">
    <div v-for="parameter of options" :key="parameter.id">
      <productSizeModal
        :parameter="parameter"
        :productConfig="productConfig"
      ></productSizeModal>
      <productSizeSelection
        :parameter="parameter"
        :productConfig="productConfig"
      ></productSizeSelection>
    </div>
  </div>
</template>

<script>
import ProductSizeModal from "@/base/core/components/product/ProductSizeModal";
import ProductSizeSelection from "@/base/core/components/product/ProductSizeSelection";

export default {
  name: "ProductSelectSimple",
  components: {
    ProductSizeModal,
    ProductSizeSelection,
  },
  props: {
    product: Object,
    priceRange: Object,
    options: Array,
    productConfig: Object,
  },
  data: () => ({}),
  methods: {},
};
</script>

