<template>
  <b-modal
    id="ProductCardModal"
    okVariant="success"
    okTitle="Doorgaan met afrekenen"
    cancelTitle="Doorgaan met winkelen"
    :title="$t('items_added_to_cart', { numberOf: quantity })"
    @ok="clickOk"
    @cancel="clickCancel"
  >
    <img :src="product.image.large" v-if="product.image.large" />
    <p class="my-4">{{ product.name }}</p>
    <p class="my-price">
      &euro;{{
        priceRange.maximum_price.final_price.value.toFixed(2).replace(".", ",")
      }}
    </p>
    <section
      class="more-products product-section"
      v-if="products && products.length > 0"
    >
      <div class="container">
        <span class="section-title d-block font-weight-normal text-center"
          >Klanten bestelden ook</span
        >
        <ClientOnly>
          <MoreProductsCarousel
            class="pt-5"
            :products="products"
            type="modal"
          />
        </ClientOnly>
      </div>
    </section>
  </b-modal>
</template>

<script>
import ClientOnly from "vue-client-only";
import MoreProductsCarousel from "../MoreProductsCarousel.vue";

export default {
  name: "ProductCardModal",
  props: {
    product: Object,
    priceRange: Object,
    quantity: Number,
    products: Array,
  },
  components: {
    ClientOnly,
    MoreProductsCarousel,
  },
  methods: {
    clickOk() {
      this.$router.push("/checkout");
    },
    clickCancel() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

