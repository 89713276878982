var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between pb-25 pt-25"},[(
      _vm.priceRange.minimum_price.final_price.value !=
      _vm.priceRange.maximum_price.final_price.value
    )?_c('div',{staticClass:"top-wrap tw1"},[(_vm.product.manufacturer_price.price.value > 0)?_c('span',{staticClass:"normal-price d-block"},[_vm._v(" "+_vm._s(_vm.$t("advice_price"))+": "),_c('span',[_vm._v(_vm._s(_vm.formatCurrency(_vm.product.manufacturer_price.price.value)))])]):_vm._e(),(
        _vm.product.manufacturer_price.price.value == null &&
        _vm.priceRange.maximum_price.regular_price.value >
          _vm.priceRange.minimum_price.final_price.value
      )?_c('div',{staticClass:"d-flex align-items-end price-selection"},[_c('span',{staticClass:"normal-price"},[_vm._v(_vm._s(_vm.$t("advice_price"))+": "),_c('span',[_vm._v(_vm._s(_vm.formatCurrency(_vm.priceRange.maximum_price.regular_price.value)))])])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-end price-selection"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getProductPriceRange.text)}}),(_vm.getProductPriceRange.discountType == 1)?_c('span',{staticClass:"discount-comment"},[_vm._v(_vm._s(_vm.$t("discount"))+" "+_vm._s(_vm.priceRange.minimum_price.discount.percent_off.toFixed(0))+"%")]):_vm._e(),(_vm.getProductPriceRange.discountType == 2)?_c('span',{staticClass:"discount-comment2"},[_vm._v(" "+_vm._s(_vm.$t("to_discount"))+" "+_vm._s(_vm.getMaxDiscount)+"% ")]):_vm._e()])]):_vm._e(),(
      _vm.priceRange.minimum_price.final_price.value ==
      _vm.priceRange.maximum_price.final_price.value
    )?_c('div',{staticClass:"top-wrap tw2"},[(_vm.product.manufacturer_price.price.value > 0)?_c('span',{staticClass:"normal-price d-block"},[_vm._v(_vm._s(_vm.$t("advice_price"))+": "),_c('span',[_vm._v(_vm._s(_vm.formatCurrency(_vm.product.manufacturer_price.price.value)))])]):_vm._e(),(
        _vm.priceRange.minimum_price.regular_price.value >
        _vm.priceRange.minimum_price.final_price.value
      )?_c('div',{staticClass:"d-flex align-items-end price-selection mb-10"},[_c('span',{staticClass:"normal-price"},[_vm._v(_vm._s(_vm.$t("advice_price"))+": "),_c('span',[_vm._v(_vm._s(_vm.formatCurrency(_vm.priceRange.minimum_price.regular_price.value)))])])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-end price-selection",domProps:{"innerHTML":_vm._s(_vm.getProductPriceRange.text)}}),(_vm.priceRange.minimum_price.discount.percent_off > 0)?_c('div',{staticClass:"d-flex align-items-end price-selection"},[_c('span',{staticClass:"discount-comment"},[_vm._v(_vm._s(_vm.$t("discount"))+" "+_vm._s(_vm.priceRange.minimum_price.discount.percent_off.toFixed(0))+"%")])]):_vm._e()]):_vm._e(),(_vm.product.brand != null)?_c('div',{staticClass:"product-logo"},[_c('b-link',{attrs:{"href":"/" + _vm.product.brand.urlKey}},[_c('img',{attrs:{"src":_vm.product.brand.logo,"alt":_vm.product.brand.title}})])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }