<template>
  <b-modal
    v-if="parameter.attribute_code == 'fkv_maten'"
    id="modal-scrollable"
    scrollable
    :title="$t('sizechart')"
  >
    <div class="text">
      <p>
        {{ $t("sizechart_warning") }}
      </p>
    </div>

    <img
      :src="productConfig.size_chart.instructions_image"
      style="width: 500px"
      :alt="productConfig.size_chart.brand.label"
      v-if="productConfig.size_chart"
    />
  </b-modal>
</template>

<script>
export default {
  name: "ProductSizeModal",
  props: {
    parameter: Object,
    productConfig: Object,
  },
};
</script>

