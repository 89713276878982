<template>
  <div class="product-parameters">
    <div class="short-desc" v-html="getShortDescription"></div>

    <ProductCardModal
      :product="product"
      :priceRange="priceRange"
      :quantity="quantity"
      :products="upSell"
    />
    <ProductPricing
      :product="product"
      :priceRange="priceRange"
      :productConfig="productConfig"
    />
    <ProductSelectSimple
      :parameters="parameters"
      :product="product"
      :options="options"
      :productConfig="productConfig"
    />
    <ProductStoreAvailability
      :product="product"
      :productConfig="productConfig"
      :options="options"
    />
    <ProductAddtoCart :product="product" />
    <div v-if="crossSell.length > 0" class="product-gallery-block">
      <h2>{{ $t("cross_sell_products") }}</h2>
      <MoreProductsCarousel :products="crossSell" />
    </div>
    <div v-if="upSell.length > 0" class="product-gallery-block">
      <h2>{{ $t("related_products") }}</h2>
      <MoreProductsCarousel :products="upSell" />
    </div>
    <ProductPaymentPartners />
    <SocialShare :product="product" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductCardModal from "@/base/core/components/product/ProductCardModal";
import ProductPricing from "@/base/core/components/product/ProductPricing";
import ProductSelectSimple from "@/base/core/components/product/ProductSelectSimple";
import ProductStoreAvailability from "@/base/core/components/product/ProductStoreAvailability";
import ProductAddtoCart from "@/base/core/components/product/ProductAddtoCart";
import ProductPaymentPartners from "@/base/core/components/product/ProductPaymentPartners";
import SocialShare from "@/base/core/components/product/ProductSocialShare";
import MoreProductsCarousel from "@/base/core//components/MoreProductsCarousel";

export default {
  name: "ProductParameters",
  components: {
    ProductPaymentPartners,
    ProductAddtoCart,
    ProductStoreAvailability,
    ProductSelectSimple,
    ProductPricing,
    ProductCardModal,
    SocialShare,
    MoreProductsCarousel,
  },
  props: {
    parameters: { type: Object },
    isInWishList: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      productConfig: "product/getCurrentConfig",
      options: "product/getCurrentProductOptions",
      crossSell: "product/getCrossSellProducts",
      upSell: "product/getUpSellProducts",
    }),

    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
    priceRange() {
      if (
        this.product &&
        this.productConfig &&
        this.product.__typename == "ConfigurableProduct"
      ) {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range;
          }
        } else if (this.productConfig.variants.length == 1) {
          return this.productConfig.variants[0].product.price_range;
        }
        return this.product.price_range;
      }
      return this.product.price_range;
    },
    getShortDescription() {
      return this.product.short_description.html
        .replace(/<p>(.*)<\/p>/gis, "$1")
        .replace(/&gt;/gs, ">")
        .replace(/&lt;/gs, "<");
    },
  },

  data: () => ({
    quantity: 1,
  }),
  methods: {
    async copyUrl(url) {
      await navigator.clipboard.writeText(url);
    },
  },
};
</script>
