<template>
  <b-tabs
    active-nav-item-class="active-opener"
    class="product--description-tab"
  >
    <b-tab active title="Omschrijving">
      <div v-html="product.description.html"></div>
      <div class="d-block sku">SKU: {{ product.sku }}</div>
    </b-tab>
    <b-tab title="Specificaties">
      <div
        v-for="(info, index) in product.information_attributes"
        :key="index"
        class="d-flex mb-4"
      >
        <span v-if="info.value != 'false'" class="category d-block"
          >{{ info.label }}:</span
        >
        <span v-if="info.value != 'false'" class="detail ml-20 d-block">{{
          CapitalizeFirst(info.value)
        }}</span>
      </div>
    </b-tab>
    <b-tab :title="`Beoordelingen (${product.review_count})`">
      <ProductReview></ProductReview>
    </b-tab>
  </b-tabs>
</template>

<script>
import { mapGetters } from "vuex";
import ProductReview from "@/base/core/components/ProductReview";

export default {
  name: "DescriptionTab",
  components: {
    ProductReview,
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
    }),
  },
  methods: {
    CapitalizeFirst(input) {
      let lower = input.toLowerCase();
      return lower.charAt(0).toUpperCase() + lower.slice(1);
    },
  },
  data: () => ({}),
};
</script>


