<template>
  <div class="store_availability">
    <div
      class="selection-category d-flex justify-content-between"
      v-if="availability.length > 0"
    >
      <span class="select-size" v-if="availability.length == 1">
        {{ $t("store_availability") }}:</span
      >
      <span class="select-size" v-else>
        {{ $t("store_availability_multipe") }}:</span
      >
    </div>
    <div
      class="d-flex flex-column flex-md-row justify-content-between more-info"
      v-if="availability.length > 0"
    >
      <div class="available-stores mb-20 mb-md-0 d-none d-sm-block">
        <div v-for="(astore, index) in availability" :key="`astores-${index}`">
          <span class="d-block font-weight-bolder"
            >{{ astore.store.name }}:</span
          >
        </div>
      </div>
      <div class="stock d-none d-sm-block">
        <div v-for="(astore, index) in availability" :key="`sstores-${index}`">
          <span v-if="astore.has_stock == true" class="d-block text-info">{{
            $t("in_stock")
          }}</span>
          <span v-if="astore.has_stock != true" class="d-block text-warning">{{
            $t("not_in_stock")
          }}</span>
        </div>
      </div>
    </div>
    <div class="available_stores_mobile d-block d-sm-none">
      <div v-for="(astore, index) in availability" :key="`astores-${index}`">
        <span class="d-inline-block">{{
          astore.store.name.replace("Wieleroutfits ", "")
        }}</span>
        <span v-if="astore.has_stock == true" class="d-inline-block text-info">
          {{ $t("in_stock") }}</span
        >
        <span
          v-if="astore.has_stock != true"
          class="d-inline-block text-warning"
        >
          {{ $t("not_in_stock") }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductStoreAvailability",
  props: {
    product: Object,
    productConfig: Object,
  },
  computed: {
    availability() {
      if (this.product.__typename == "SimpleProduct") {
        return this.product.store_availability;
      }
      if (
        this.productConfig &&
        this.product.__typename == "ConfigurableProduct"
      ) {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.store_availability;
          }
        }
      }

      return [];
    },
  },
};
</script>


