<template>
  <ClientOnly>
    <Carousel
      class="more-products--carousel"
      :autoplay="false"
      :loop="false"
      :navigationEnabled="true"
      :perPage="slidePerPage"
      :scrollPerPage="false"
      :paginationEnabled="false"
      navigationPrevLabel="<i class='lnr lnr-chevron-left'></i>"
      navigationNextLabel="<i class='lnr lnr-chevron-right'></i>"
    >
      <Slide
        v-for="product of products"
        :key="product.sku"
        class="more-products--slide position-relative"
      >
        <ProductCard :product="product" />
      </Slide>
    </Carousel>
  </ClientOnly>
</template>

<script>
//import { Carousel, Slide } from 'vue-carousel'
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";

import { mapActions } from "vuex";
import ProductCard from "@/base/core/components/ProductCard";

export default {
  name: "MoreProductsCarousel",
  components: {
    ClientOnly,
    Carousel: () =>
      !isServer ? import("vue-carousel").then((m) => m.Carousel) : null,
    Slide: () =>
      !isServer ? import("vue-carousel").then((m) => m.Slide) : null,
    ProductCard,
  },
  props: {
    products: { type: Array },
  },
  mounted() {
    if (!isServer) {
      window.addEventListener("resize", this.setNoOfSlidePerPage);
      window.addEventListener("load", this.setNoOfSlidePerPage);
      this.setNoOfSlidePerPage();
    }
  },
  destroyed() {
    if (!isServer) {
      window.removeEventListener("resize", this.setNoOfSlidePerPage);
      window.removeEventListener("load", this.setNoOfSlidePerPage);
    }
  },
  data() {
    return {
      slidePerPage: this.setNoOfSlidePerPage(),
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    setNoOfSlidePerPage() {
      if (isServer) {
        if (this.type == "modal") {
          this.slidePerPage = 1;
        } else {
          this.slidePerPage = 4;
        }
      } else {
        if (this.type == "modal") {
          this.slidePerPage = 1;
        } else if (window.innerWidth < 768) {
          this.slidePerPage = 2;
        } else if (window.innerWidth > 767 && window.innerWidth < 992) {
          this.slidePerPage = 3;
        } else {
          this.slidePerPage = 4;
        }
      }
    },
    toggleWishListStatus(productId) {
      this.setProductStatus({ productId });
    },
  },
};
</script>
