<template>
  <div
    v-if="
      parameter.attribute_code == 'fkv_maten' &&
      productConfig.size_chart != null &&
      customMeasures.length > 0
    "
    class="productMeasureCompare size-selection"
  >
    <div class="selection-category d-flex justify-content-between">
      <span class="select-size">{{ $t("which_measure_in_cupboard") }}</span>
    </div>
    <div v-if="parameter.attribute_code == 'fkv_maten'" class="select-wrapper">
      <ul class="size-selection--available-sizes">
        <li v-for="opt of customMeasures" :key="opt.value_index">
          <label class="custom-radio-input">
            <input
              :id="`${parameter.attribute_code}-${opt.value_index}`"
              v-model="priceSize"
              :name="`${parameter.attribute_code}-select`"
              :value="opt.value_index"
              type="radio"
              v-on:click="processOption(parameter.index, opt.value_index)"
            />
            <span class="custom-radio-input--btn font-weight-normal">{{
              opt.label
            }}</span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductMeasureCompare",
  props: {
    parameter: { type: Object },
    productConfig: Object,
  },
  computed: {
    ...mapGetters({
      customMeasures: "product/getCurrentCustomMeasures",
    }),
    priceSize: {
      get() {
        return this.$store.getters["product/getCurrentChildSku"];
      },
      set(value) {
        this.$emit("selectSize", value);
      },
    },
  },
  methods: {
    processOption(index, value) {
      this.$store.commit("product/setOptionValue", {
        index: index,
        value: value,
      });
    },
  },
};
</script>







